<template>
  <div class="relative flex items-center">
    <input
      class="pr-16 inline-block fs-exclude"
      :type="hidePassword ? 'password' : 'text'"
      :value="password"
      ref="password"
      @input="updateSelf($event.target.value)"
    />
    <div
      v-if="canView"
      class="absolute right-0 p-4 text-xs text-gray-500 default-transition default-animation "
      :class="{ '': isValid }"
      @click="toggleView"
    >
      <font-awesome-icon
        class="mt-1 text-lg "
        :icon="['far', hidePassword ? 'eye' : 'eye-slash']"
      />
      <transition
        enter-active-class="bounceIn"
        leave-active-class="miniFadeOutRight"
        mode="out-in"
      >
        <font-awesome-icon
          class="mt-1 text-lg text-teal-500 ml-4  "
          v-if="isValid && showValid"
          key="valid"
          :icon="['far', 'check']"
        />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: String,
    canView: Boolean,
    isFocused: Boolean,
    showValid: Boolean
  },
  model: {
    prop: "password",
    event: "input"
  },
  data() {
    return {
      hidePassword: true
    };
  },
  computed: {
    isValid: function() {
      return this.password.length >= 5;
    }
  },
  methods: {
    updateSelf: function(name) {
      this.$emit("input", name);
    },
    toggleView: function() {
      this.hidePassword = !this.hidePassword;
    }
  },
  mounted() {
    if (this.isFocused) {
      this.$refs.password.focus();
    }
  }
};
</script>
